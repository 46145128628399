import { useEffect, useState } from 'react'


function useDebounce<T>(value: T, delay?: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value)

    useEffect(() => {
        // Update debounced value after delay
        const timer = setTimeout(() => setDebouncedValue(value), delay || 500)
        // Cancel the timeout if value changes (also on delay change or unmount)
        //         // This is how we prevent debounced value from updating if value is changed ...
        //         // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
            clearTimeout(timer)
        }
    }, [value, delay])


    return debouncedValue
}

export default useDebounce


